import React, { useEffect, useState } from 'react';
import { TEMPLATE_MANAGEMENT_CONFIG, TEMPLATE_MANAGEMENT_DEFAULT_SETUP } from 'components/template-management/constants';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Loader from 'components/ui-components/Loader';
import AssetManagementHelpers from 'components/asset-management/helpers';
import AssetManagementConfigProvider from 'components/asset-management/context/asset-management-config.context';
import AssetManagementSelector from 'components/asset-management/components/selector';
import { AssetManagementConfigEnriched } from 'components/asset-management/types/asset-management-config.type';
import { TemplateSelectorProps } from 'components/template-management/types/template-selector-props.type';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import TemplateManagementSelectorTile from './tile';

const { storeName, type, setupResource } = TEMPLATE_MANAGEMENT_CONFIG;

const TemplateManagementSelector: React.FC<TemplateSelectorProps> = ({ dataFilters, subType, addedContent, collectionQuery, capeLibrary, onSelectItem }) => {
    const [enrichedConfig, setEnrichedConfig] = useState<AssetManagementConfigEnriched | null>(null);
    const [selectedItemLoading, setSelectedItemLoading] = useState<boolean>(false);

    //Initialize the Component Store for Template Management so we have acces to the filtersetup.
    useEffect(() => {
        const initManagement = async () => {
            const setup = await AssetManagementHelpers.getAssetManagementSetup(setupResource, TEMPLATE_MANAGEMENT_DEFAULT_SETUP);
            const enrichedConfig = await AssetManagementHelpers.getEnrichedConfig(TEMPLATE_MANAGEMENT_CONFIG, setup);
            if (enrichedConfig) setEnrichedConfig(enrichedConfig);
        };
        if (!ComponentStoreHelpers.get(storeName)) {
            AssetManagementHelpers.init(TEMPLATE_MANAGEMENT_CONFIG);
        }
        initManagement();
    }, []);

    // For templates we need to fetch the full template to get the complete data.
    const selectTemplate = async (item: TemplateAsset) => {
        setSelectedItemLoading(true);
        const template = await (async () => {
            if (item.incompleteData) {
                const completeTemplate = await AssetManagementService.getAsset(item._id);
                return completeTemplate;
            }
            return item;
        })();
        onSelectItem(template as TemplateAsset);
    };

    return (
        <Loader isLoading={!enrichedConfig}>
            <AssetManagementConfigProvider config={enrichedConfig as AssetManagementConfigEnriched}>
                <AssetManagementSelector
                    onSelect={(item) => selectTemplate(item as TemplateAsset)}
                    dataFilters={dataFilters}
                    addedContent={addedContent}
                    preFilters={subType ? { subType } : undefined}
                    collectionQuery={collectionQuery}
                    type={type}
                    hideTabs={capeLibrary}
                    selectedItemLoading={selectedItemLoading}
                    getAssetsAlternative={capeLibrary ? AssetManagementService.getAssetLibraryBatch : undefined}
                    assetTile={<TemplateManagementSelectorTile />}
                />
            </AssetManagementConfigProvider>
        </Loader>
    );
};

export default TemplateManagementSelector;
